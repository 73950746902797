<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Verify Email -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <img
                        src="@/assets/images/logo/logo.png"
                        alt="Logo"
                        class="logo-auth"
                    />
                    <h2 class="brand-text text-primary ml-1">Leechall.io</h2>
                </b-link>

                <b-card-title class="mb-1">Verify your email ✉️</b-card-title>
                <b-card-text>
                    We've sent a link to your email address. Please follow the
                    link inside to continue.
                </b-card-text>
                <b-card-text class="mb-2">
                    If you do not see the email in a few minutes, check your
                    “junk mail” folder or “spam” folder.
                </b-card-text>
                <b-card-text>
                    Didn't receive an email? Or link not work?
                </b-card-text>
                <b-button
                    variant="primary"
                    block
                    :disabled="disable"
                    @click="sendMail()"
                    >{{ button_status }}</b-button
                >
                <b-button variant="danger" block :to="{ name: 'auth-logout' }"
                    >Logout</b-button
                >
            </b-card>
            <!-- /Verify Email -->
        </div>
    </div>
</template>

<script>
import { BCard, BLink, BCardText, BCardTitle, BButton } from "bootstrap-vue";
import axios from "axios";

export default {
    components: {
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BButton,
    },
    data() {
        return {
            countDown: 60,
            button_status: "Resend",
            disable: false,
        };
    },

    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.disable = true;
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else {
                this.button_status = "Resend";
                this.disable = false;
                this.countDown = 60;
            }
        },
        async sendMail() {
            axios
                .post("auth/email/verification-notification")
                .then((response) => {
                    this.button_status = response.data.message;
                    if (!response.data.success) {
                        setTimeout(() => (window.location.href = "/"), 1000);
                    } else {
                        this.countDownTimer();
                    }
                })
                .catch((err) => {
                    this.button_status = err.response.data.message;
                    this.countDownTimer();
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
